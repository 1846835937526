* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  margin: 0;
  overflow-x: hidden;
}

html {
  background-color: #1360ef;
}

@media print {
  .no-print {
    display: none !important;
  }

  .print-cell-fw {
    width: 200px !important;
  }
}

.MuiMenu-list {
  padding: 8px !important;
  cursor: pointer;
}
