.radioButton {
  width: auto;
}

.landing-page__img {
  /* //position: absolute; */
  /* // height: auto; */
  display: flex;
  justify-content: center;
  /* // padding-top: 10px; */
  margin-top: -20px;
}
.landing-page {
  padding: 50px 0;
  /* // background: radial-gradient(#fff,#d4d4d0c5); */
  background-color: white;
  height: 100vh;
}

.landing-img__img {
  width: 15%;
}

.landing-img {
  max-width: auto;
  max-height: 500px;
  object-fit: contain;
  margin-left: 40px;
}
.landing-form-container {
  background-color: #fff;
  max-width: 600px;
  max-height: 600px;
  position: relative;
  text-align: center;
  padding: 20px 0;
  margin: auto;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  margin-top: 80px;
}
.landing-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: space-around;
  margin-top: 80px;
}

.landing-form-container span {
  font-weight: bold;
  padding: 0 10px;
  color: #555;
  cursor: pointer;
  width: 180px;
  display: inline-block;
}
.landing-form-btn {
  display: inline-block;
}
.landing-form-container form {
  /* // max-width: 650px; */
  padding: 0 20px;
  /*   
    // position: absolute;
    // // top: 130px;
    // display: inline-block; */
  transition: transform 1s;
}

form input {
  width: 100%;
  height: 30px;
  margin: 10px 0;
  padding: 0 10px;
  border: 1px solid #ccc;
}

form .login-btn {
  width: 100%;
  border: none;
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  color: white;
  font-size: 14px;
  background-color: rgb(40, 99, 230);
  background-color: #1890ff;
}

form .login-btn:hover {
  background-color: rgb(229, 91, 47);
  /* //background-color: rgb(207,67,93); */
}

form .login-btn:focus {
  outline: none;
}

#LoginForm {
  left: -300px;
}

#RegistrationForm {
  left: 0;
}

form a {
  font-size: 12px;
}

#Indicator {
  width: 100px;
  border: none;
  background-color: #ff523b;
  height: 3px;
  margin: 8px;
  transform: translate(280px);
  /* // transition: transform 1s; */
}

#Indicator-tab {
  width: 100px;
  border: none;
  background-color: #ff523b;
  height: 3px;
  margin: 8px;
  transform: translate(150px);
  /* // transition: transform 1s; */
}

form .login-btn {
  width: 100%;
  border: none;
  cursor: pointer;
  margin: 10px 0;
}

.landing-col-textbox {
  border: solid;
  border-width: 3px;
  border-radius: 12px;
  border-color: #555555;
  max-width: 60%;
  margin-top: 10px;
  padding: 40px;

  margin-bottom: 20px;
  margin-left: 30px;
}

.landing-col-text {
  font-size: 18px;
}

.info-box {
  font-size: 28px;
  margin-left: 9rem;
}
.moving-info-box {
  font-size: 32px;
  color: #f85014;
}
