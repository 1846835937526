.App {
  min-height: calc(100vh - 40px);
}

.loading-dot {
  font-size: 2rem;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
